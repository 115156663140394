<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
        <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>批量修改</el-breadcrumb-item>
  <el-breadcrumb-item>标题修改</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">
  <el-form ref="form" class="demo-form-inline" label-width="120px">
    <el-form-item label="选择分类">
      <el-select v-model="userCategorysID" placeholder="请选择">
      <el-option
        v-for="group in userCategorys"
        :key="group.id"
        :label="group.name"
        :value="group.id">
        <el-option
          v-for="item in group.subCategoryList"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-option>
    </el-select>
    </el-form-item>
    <el-form-item label="删除关键字">
      <el-input v-model="btShanchu" placeholder="关键词"></el-input>
    </el-form-item>
    <el-row>
      <el-col :span="12">
      <el-form-item label="替换关键字">
        <el-input v-model="btQiantihuan" placeholder="原关键字"></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="12">
      <el-form-item label="替换成">
        <el-input v-model="btHoutihuan" placeholder="现关键字"></el-input>
      </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
      <el-form-item label="添加前后缀">
        <el-input v-model="btQianhouzhui" placeholder="前关键词"></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="12">
      <el-form-item label="+ 原名称 +">
        <el-input v-model="btHouhouzhui" placeholder="后关键词"></el-input>
      </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</el-card>
<!-- 内容 结束 -->
    </el-main>
    </el-container>
</el-container>

</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'

import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'
import { listuserCategory } from 'network/usercategory'
import { piliangSave } from 'network/piliang'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Piliang',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    const formData = reactive({
      userCategorysID: '',
      btShanchu: '',
      btQiantihuan: '',
      btHoutihuan: '',
      btQianhouzhui: '',
      btHouhouzhui: ''
    })

    const state = reactive({
      userCategorys: []
    })

    // 确认提交
    const onSubmit = () => {
      if (formData.userCategorysID === '') {
        ElMessage.warning({
          message: '请选择编辑分类'
        })
        return false
      }
      piliangSave(formData).then(res => {
        if (res.status === 1) {
          ElMessage.success({
            message: res.message,
            type: 'success'
          })
        } else {
          state.showBtn = false
        }
      })
    }

    onMounted(() => {
      // 获取授权用户的商品自定义分类列表
      listuserCategory().then(res => {
        if (res.status === 1) {
          state.userCategorys = res.result
          // console.log(res.result)
        } else {
          state.userCategorys = []
        }
      })
    })
    return {
      ...toRefs(formData),
      ...toRefs(state),
      onSubmit,
      navActive: '4-1'
    }
  }
})
</script>
<style lang="scss" scoped>
</style>
