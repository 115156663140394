import { request } from './request'

/*
* 获取授权用户的商品自定义分类列表
*/
export function piliangSave (data) {
  return request({
    url: '/api/alibaba/piliang/save',
    method: 'post',
    data
  })
}
